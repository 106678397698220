import React from 'react'
import Navbar from '../components/Navbar'
import SEO from '../components/SEO'
import {FaMapMarkerAlt, FaDollarSign} from "react-icons/fa"
import Layout from '../components/Layout'

const reactjsJob = () => {
  return (
    <>
      <Layout>
        <SEO title="Urgent ReactJS Frontend Dev (Remote $2k)" />
        <Navbar bgColor="#EFF0F1" />
        <section className="job-title-section">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h1>React/Redux Frontend Developer (Remote) </h1>
                <ul className="job-tag">
                  <li>ReactJS</li>
                  <li>Redux</li>
                  <li>Responsive Frontend</li>
                </ul>
                <ul className="job-meta">
                  <li><FaDollarSign/> 1200-2000</li>
                  <li><FaMapMarkerAlt/> Remote, Vietnam</li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        <section className="job-details-section">
          <div className="container">
            <div className="row">
              <div className="col-md-8 mx-auto">
                <div className="job-description-wrapper">
                  <div className="job-description">
                    <h2>The Project</h2>
                    <p>Join our team immediately and remotely from any location in Vietnam for this exciting
                    project! We're building a new eLearning SaaS from scratch and are now looking for a
                    talented, fast working frontend developer to take full ownership over the React based
                      frontend.</p>
                  </div>

                  <div className="job-description">
                    <h2>Job Description</h2>
                    <ul>
                      <li>Take the key role for the frontend of our eLearning platform</li>
                      <li>Write frontend logic (React/Redux)</li>
                      <li>Turn the beautiful UI design into reusable frontend components and a responsive layout</li>
                      <li>Work with the UI designer, backend developers and product team</li>
                    </ul>
                  </div>
                  <div className="job-description">
                    <h2>Your Skills and Experience</h2>
                    <ul>
                      <li>Mid-level to senior-level: you code fast & you code well</li>
                      <li>At least 4 years experience with JavaScript/CSS</li>
                      <li>At least 2 years experience with React/Redux.</li>
                      <li>Experience turning UI into pixel perfect code (e.g. Zeplin, Figma)</li>
                      <li>Experience with modern CSS frameworks and responsive design</li>
                      <li>Ability to work in groups or independently.</li>
                      <li>Excellent working attitude, communication skills and critical thinking.</li>
                      <li>Experience using Ant Design React UI library is preferred but not a must</li>
                      <li>English skills is preferred but not a must</li>
                      <li>Experience with working remotely preferred but not a must</li>
                    </ul>
                  </div>

                  <div className="job-description">
                    <h2>Why You'll Love Working Here</h2>
                    <ul>
                      <li>Start immediately or late February/early March</li>
                      <li>Salary $1200-2000/month for full-time work (flexible hours, but we prefer if this is your primary job)</li>
                      <li>Remote work - we pay extra for your home office cost or for a coworking/office space of your choice</li>
                      <li>Attractive portfolio piece for your CV</li>
                      <li>Freelance or full employment possible</li>
                      <li>Project duration: 4-5 months, likely longer</li>
                    </ul>
                  </div>

                  <div className="job-description">
                    <a href="mailto:contact@pandigita.com" className="btn btn-primary">Email Us</a>
                  </div>
                  
                </div>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    </>
  )
}

export default reactjsJob
